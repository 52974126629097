export const FETCH_ENCRYPTION_KEYS_REQUEST = 'FETCH_ENCRYPTION_KEYS_REQUEST';
export const FETCH_ENCRYPTION_KEYS_SUCCESS = 'FETCH_ENCRYPTION_KEYS_SUCCESS';
export const FETCH_ENCRYPTION_KEYS_ERROR = 'FETCH_ENCRYPTION_KEYS_ERROR';

export function requestIVandKeyFromAWS() {
  return {
    types: [FETCH_ENCRYPTION_KEYS_REQUEST, FETCH_ENCRYPTION_KEYS_SUCCESS, FETCH_ENCRYPTION_KEYS_ERROR],
    fetch: {
      api: 'aws',
      url: 'get/ssmparams',
    },
  };
}
