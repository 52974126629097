import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StaticRoutes } from '../Routes';
import Header from './Header';
import RippleButton from './Forms/RippleButton';
import { AOP_CLIENT_ID } from '../constants';

const ThankYouPage = (props) => {
  const goToHistory = () => props.history.push(StaticRoutes.HISTORY);
  const newTransfer = () => props.history.goBack();
  const logOutAop = () => {
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('clientId');
    sessionStorage.removeItem('userId');
    window.parent.postMessage({ logout: true }, '*');
  };

  return (
    <div className="thank-you page">
      <section>
        <Header
          title="Transfer Funds"
        />
      </section>
      <section>
        <h3>Thank You!</h3>
        {!props.isAopClient &&
          <p>
            Your request has been received. You can view the
            status of your request by going to History.
          </p>
        }
        {props.isAopClient &&
          <p>
            Your request has been received. Click New Transfer to schedule another deposit.
          </p>
        }
        <div className="actions">
          {!props.isAopClient &&
            <RippleButton onClick={goToHistory} className="primary">
              transfer history
            </RippleButton>
          }
          <RippleButton onClick={newTransfer} className="primary">
            new transfer
          </RippleButton>
          {props.isAopClient &&
            <RippleButton onClick={logOutAop} className="default">
              get started
            </RippleButton>
          }
        </div>
      </section>
    </div>
  );
};

ThankYouPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }),
  isAopClient: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isAopClient: state.clientId === AOP_CLIENT_ID,
  };
}

export default connect(mapStateToProps)(ThankYouPage);
