import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import publishPageHeight from '../../publishPageHeight';
import RippleButton from '../Forms/RippleButton';

/* eslint-disable */
const normalize = (value) => {
  if (!value) return value;

  const cleanValue = parseFloat(value.toString().replace(/\./g, '').replace(/,/g, ''));
  return (cleanValue / 100).toFixed(2);
}

const convertAmountToNumber = (amount) => {
  return Number(normalize(amount)).toFixed(2);
}

class WireTransferDepositEquities extends Component {
  componentDidUpdate() {
    publishPageHeight();
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form className="confirmation" onSubmit={handleSubmit}>
        <section id="wire-deposit-equities">
          <h4>Instructions</h4>
          <p>Provide the instructions below to your bank or brokerage firm. Please do not initiate a wire until
          your account has been approved. Doing so may result in the wire being returned and additional fees
          incurred. TradeStation does not accept third party wire transfers. Therefore, the name on your TradeStation
          account and the name on the external account must match exactly.</p>
          <dl>
            <dt id="deposit-amount">Deposit Amount:&nbsp;
              <span className="amount">{`${(this.props.formValues.values.transferAmount || 0).toLocaleString(
                'en-US', { style: 'currency', currency: this.props.formValues.values.transferCurrency }
              )}`}</span></dt>
          </dl>
          <div id="receiving-bank" className="instruction-block">
            <h5>Beneficiary Bank</h5>
            <dl>
              <dt>Beneficiary Bank:</dt>
              <dd>JPMorgan Chase</dd>
              <dt>Address:</dt>
              <dd>One Chase Manhattan Plaza<br />
                7th floor<br />
                New York, NY 10005</dd>
              <dt>Phone:</dt>
              <dd>(877) 204-1123</dd>
              <dt>ABA Routing:</dt>
              <dd>021-000-021</dd>
              <dt>SWIFT BIC Routing:</dt>
              <dd>CHASUS33</dd>
              <dt />
            </dl>
          </div>
          <div id="recipient" className="instruction-block">
            <h5>Beneficiary Account</h5>
            <dl>
              <dt>Beneficiary Account:</dt>
              <dd>TradeStation Securities</dd>
              <dt>Address:</dt>
              <dd>8050 SW 10th Street,<br />
                Suite 2000<br />
                Plantation, FL 33324</dd>
              <dt>Account Title:</dt>
              <dd>Trade Station Securities</dd>
              <dt>Account Number:</dt>
              <dd>066628636</dd>
            </dl>
          </div>
          <div id="beneficiary" className="instruction-block">
            <h5>For Further Credit Instructions</h5>
            <dl>
              <dt>Further Credit To:</dt>
              <dd>{this.props.formValues.values.fundsDestinationAccount.accountTitle}</dd>
              <dt>Account Number:</dt>
              <dd>{this.props.formValues.values.fundsDestinationAccount.accountNumber}</dd>
              <dt>By Order Of:</dt>
              <dd>&#60;Account Title at Sending Bank&#62;</dd>
              <dt />
              <dd className="description">
                The title of your TradeStation Securities account and the title&nbsp;
              of the account from which you are transferring funds must match.</dd>
            </dl>
          </div>
          <div className="actions">
            <RippleButton className="default">edit</RippleButton>
            {this.props.showPrintButton &&
            <RippleButton className="primary" onClick={(e) => this.props.printPage(e)}>print</RippleButton>}
          </div>
        </section>
      </form>
    );
  }
}

WireTransferDepositEquities.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  printPage: PropTypes.func.isRequired,
  showPrintButton: PropTypes.bool.isRequired,
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      transferAmount: PropTypes.string,
      transferCurrency: PropTypes.string,
      fundsDestinationAccount: PropTypes.shape({
        accountTitle: PropTypes.string,
        accountNumber: PropTypes.string,
        brokerAddress: PropTypes.shape({
          address1: PropTypes.string,
          address2: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
          postalCode: PropTypes.string,
          country: PropTypes.string,
        }),
      }),
    }),
  }),
};

export default reduxForm({
  form: 'wireTransferDepositForm',
  destroyOnUnmount: false,
})(WireTransferDepositEquities);
