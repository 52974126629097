
/* eslint no-param-reassign: ["error", { "props": false }] */
export const SUBMIT_WIRE_WITHDRAWAL_REQUEST = 'SUBMIT_WIRE_WITHDRAWAL_REQUEST';
export const SUBMIT_WIRE_WITHDRAWAL_SUCCESS = 'SUBMIT_WIRE_WITHDRAWAL_SUCCESS';
export const SUBMIT_WIRE_WITHDRAWAL_ERROR = 'SUBMIT_WIRE_WITHDRAWAL_ERROR';
export const FETCH_WIRE_WITHDRAWALS_REQUEST = 'FETCH_WIRE_WITHDRAWALS_REQUEST';
export const FETCH_WIRE_WITHDRAWALS_SUCCESS = 'FETCH_WIRE_WITHDRAWALS_SUCCESS';
export const FETCH_WIRE_WITHDRAWALS_ERROR = 'FETCH_WIRE_WITHDRAWALS_ERROR';
export const FETCH_WIRE_WITHDRAWAL_REQUEST = 'FETCH_WIRE_WITHDRAWAL_REQUEST';
export const FETCH_WIRE_WITHDRAWAL_SUCCESS = 'FETCH_WIRE_WITHDRAWAL_SUCCESS';
export const FETCH_WIRE_WITHDRAWAL_ERROR = 'FETCH_WIRE_WITHDRAWAL_ERROR';

export function submitWireTransfer(data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [SUBMIT_WIRE_WITHDRAWAL_REQUEST, SUBMIT_WIRE_WITHDRAWAL_SUCCESS, SUBMIT_WIRE_WITHDRAWAL_ERROR],
    fetch: {
      url: '/Accounts/Wires',
      options: { method: 'post', body: JSON.stringify(data) },
    },
  };
}

export function fetchWireTransfers() {
  return {
    types: [FETCH_WIRE_WITHDRAWALS_REQUEST, FETCH_WIRE_WITHDRAWALS_SUCCESS, FETCH_WIRE_WITHDRAWALS_ERROR],
    fetch: {
      url: '/Users/Transactions/Wires?$count=true&$orderby=ClientReferenceId desc&$top=20',
    },
  };
}

export function fetchWireTransfer(requestId) {
  return {
    types: [FETCH_WIRE_WITHDRAWAL_REQUEST, FETCH_WIRE_WITHDRAWAL_SUCCESS, FETCH_WIRE_WITHDRAWAL_ERROR],
    fetch: {
      url: `/ScheduledEvents/GetScheduledEventDetails/${requestId}`,
    },
  };
}

// export function fetchWireTransfer(requestId) {
//   return (state) => {
//     return {
//       type: FETCH_WIRE_WITHDRAWAL_SUCCESS,
//       payload: state.scheduledTransfers.find(x => x.id === Number(requestId)).details,
//     };
//   };
// }
