import * as encryptionActionsTypes from '../actions/encryptionActions';
import { setKeyandIV } from '../components/Encryption/EncryptionDecryptionPage';

export default function (state = { data: [], everLoaded: false }, action) {
  switch (action.type) {
    case encryptionActionsTypes.FETCH_ENCRYPTION_KEYS_SUCCESS: {
      const accounts = action.payload;
      setKeyandIV(accounts);
      return { data: accounts, everLoaded: true };
    }
    default:
      return state;
  }
}
