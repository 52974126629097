/* eslint no-param-reassign: ["error", { "props": false }] */
export const SUBMIT_INTERNAL_TRANSFER_REQUEST = 'SUBMIT_INTERNAL_TRANSFER_REQUEST';
export const SUBMIT_INTERNAL_TRANSFER_SUCCESS = 'SUBMIT_INTERNAL_TRANSFER_SUCCESS';
export const SUBMIT_INTERNAL_TRANSFER_ERROR = 'SUBMIT_INTERNAL_TRANSFER_ERROR';
export const FETCH_INTERNAL_TRANSFER_REQUEST = 'FETCH_INTERNAL_TRANSFER_REQUEST';
export const FETCH_INTERNAL_TRANSFER_SUCCESS = 'FETCH_INTERNAL_TRANSFER_SUCCESS';
export const FETCH_INTERNAL_TRANSFER_ERROR = 'FETCH_INTERNAL_TRANSFER_ERROR';

export function submitInternalTransfer(data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [SUBMIT_INTERNAL_TRANSFER_REQUEST, SUBMIT_INTERNAL_TRANSFER_SUCCESS, SUBMIT_INTERNAL_TRANSFER_ERROR],
    fetch: {
      url: '/Accounts/Journals',
      options: { method: 'post', body: JSON.stringify(data) },
    },
  };
}

export function fetchInternalTransfer(requestId) {
  return {
    types: [FETCH_INTERNAL_TRANSFER_REQUEST, FETCH_INTERNAL_TRANSFER_SUCCESS, FETCH_INTERNAL_TRANSFER_ERROR],
    fetch: { url: `/Journals/${requestId}` },
  };
}
