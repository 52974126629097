
let encryptionKey = null;
let encryptionIV = null;

export function setKeyandIV(object) {
  encryptionKey = object[1];
  encryptionIV = object[0];
}

export const encryption = (account) => {
  const account1 = account;
  if (account1 === '') {
    return account;
  }
  if (account1 === null) {
    return account;
  }
  /* eslint-disable global-require */
  const crypto = require('crypto-js');
  /* eslint-enable global-require */
  const key = crypto.enc.Utf8.parse(encryptionKey);
  const ivArray = crypto.enc.Utf8.parse(encryptionIV);
  const aesEncryptor = crypto.AES.encrypt(account, key, {
    iv: { ivArray },
    mode: crypto.mode.ECB,
    padding: crypto.pad.ZeroPadding,
  });
  return crypto.enc.Base64.stringify(aesEncryptor.ciphertext);
};

export function decryption(account) {
  const account1 = account;
  if (account1 === '') {
    return account;
  }
  if (account1 === null) {
    return account;
  }
  /* eslint-disable global-require */
  const crypto = require('crypto-js');
  /* eslint-enable global-require */
  // Start decryptor
  const key = crypto.enc.Utf8.parse(encryptionKey);
  const ivArray = crypto.enc.Utf8.parse(encryptionIV);
  // const bufferAccount = crypto.enc.Base64.parse(account);
  const aesDecryptor = crypto.AES.decrypt(account, key, {
    iv: { ivArray },
    mode: crypto.mode.ECB,
    padding: crypto.pad.ZeroPadding,
  });
  return crypto.enc.Utf8.stringify(aesDecryptor);
}
