import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './AccountNavigation.css';
import RippleButton from '../Forms/RippleButton';
import { StaticRoutes } from '../../Routes';

const AccountNavigation = (props) => {
  return (
    <div className="account-nav">
      <RippleButton onClick={props.redirectToLoginPage} className="logout primary">
            Logout
      </RippleButton>
    </div>
  );
};

AccountNavigation.propTypes = {
  redirectToLoginPage: PropTypes.func,
};

export default connect(null,
  dispatch => {
    return {
      redirectToLoginPage: () => {
        sessionStorage.clear();
        sessionStorage.clear();
        window.history.pushState({}, document.title, window.location.pathname);
        window.parent.postMessage({ logout: true }, '*');
        window.location.href = StaticRoutes.ROOT;
        dispatch();
      },
    };
  },
)(AccountNavigation);
