import moment from 'moment-timezone';
import { decryption } from '../components/Encryption/EncryptionDecryptionPage';

export const achTransfer = ach => {
  return ({
    accountNumber: decryption(ach.AccountNumber),
    fundsDestination: ach.ScheduledEventParams.directionField === 'Debit'
      ? `bank_${ach.ScheduledEventParams.bankIdField}`
      : `brokerage_${decryption(ach.AccountNumber)}`,
    fundsOrigin: ach.ScheduledEventParams.directionField === 'Debit'
      ? `brokerage_${decryption(ach.AccountNumber)}`
      : `bank_${ach.ScheduledEventParams.bankIdField}`,
    transferAmount: Number(ach.Amount).toFixed(2),
    transferCurrency: ach.ScheduledEventParams.currencyCodeField,
    frequency: ach.ScheduledEventParams.scheduledEventFrequencyIDField,
    calendar: moment(ach.ScheduledEventParams.startDateField).format('YYYY-MM-DD') === '0001-01-01' ?
      moment() :
      moment(ach.ScheduledEventParams.startDateField),
    transferBalanceChosen: ach.ScheduledEventParams.accountBalanceField === '1',
    iraDeposit: {
      iraTypeId: ach.ScheduledEventParams.iRATypeIdField,
      iraContributionTypeId: ach.ScheduledEventParams.iRAContributionTypeIdField,
      iraContributionYear: ach.ScheduledEventParams.iRAContributionYearField,
      iraDepositTypeId: ach.ScheduledEventParams.iRAContributionDepositTypeIdField,
      iraRolloverTypeId: ach.ScheduledEventParams.iRARolloverTypeIdField,
      iraContributionSourceTypeId: ach.ScheduledEventParams.iRAContributionSourceTypeIdField,
    },
    iraWithdrawal: {
      distributionTypeId: ach.ScheduledEventParams.iRADistributionTypeIdField,
      federalTaxWithholdingPercentage: ach.ScheduledEventParams.fedWithholdingPercentageField,
      iraDistributionReasonTypeId: ach.ScheduledEventParams.iRADistributionReasonIdField,
      iraTypeId: ach.ScheduledEventParams.iRATypeIdField,
      stateTaxWithholdingPercentage: ach.ScheduledEventParams.stateWithholdingPercentageField,
      withholdFederalTaxes: (ach.ScheduledEventParams.fedWithholdingAmountField !== '0' ||
      ach.ScheduledEventParams.fedWithholdingPercentageField !== '0'),
      withholdStateTaxes: (ach.ScheduledEventParams.stateWithholdingAmountField !== '0' ||
      ach.ScheduledEventParams.stateWithholdingPercentageField !== '0'),
      federalTaxWithholdingAmount: ach.ScheduledEventParams.fedWithholdingAmountField,
      stateTaxWithholdingAmount: ach.ScheduledEventParams.stateWithholdingAmountField,
      recharacterizationYear: ach.ScheduledEventParams.iRADistributionYearField,
      directRolloverAccountTypeId: ach.ScheduledEventParams.directRolloverAccountTypeIdField,
      excessContributionYear: ach.ScheduledEventParams.excessContributionYearField,
      isBeforeTaxDeadline: ach.ScheduledEventParams.isBeforeTaxDeadlineField || false,
    },
    fdcnId: ach.ScheduledEventParams.fdcnIdField,
    bankId: ach.ScheduledEventParams.bankIdField,
    isEdit: true,
  });
};

export const checkTransfer = check => ({
  deliveryMethod: check.DeliveryType === 'Regular' ? 'Standard' : 'Overnight',
  fundsOriginAccount: { accountNumber: check.AccountNumber },
  fundsOriginAccountKey: `brokerage_${check.AccountNumber}`,
  transferAmount: Number(check.Amount).toFixed(2),
  transferCurrency: check.CurrencyCode,
});

export const internalTransfer = journal => ({
  accountNumber: decryption(journal.AccountNumber),
  fundsOrigin: `brokerage_${decryption(journal.ScheduledEventParams.fromAccountNumberField)}`,
  fundsDestination: `brokerage_${decryption(journal.ScheduledEventParams.toAccountNumberField)}`,
  transferAmount: Number(journal.Amount).toFixed(2),
  transferCurrency: journal.ScheduledEventParams.currencyCodeField,
  frequency: journal.ScheduledEventParams.scheduledEventFrequencyIDField,
  calendar: moment(journal.ScheduledEventParams.startDateField).format('YYYY-MM-DD') === '0001-01-01' ?
    moment() :
    moment(journal.ScheduledEventParams.startDateField),
  transferBalanceChosen: journal.ScheduledEventParams.accountBalanceField === '1',
  iraDeposit: {
    iraTypeId: journal.ScheduledEventParams.iRATypeIdField,
    iraContributionTypeId: journal.ScheduledEventParams.iRAContributionTypeIdField,
    iraContributionYear: journal.ScheduledEventParams.iRAContributionYearField,
    iraDepositTypeId: journal.ScheduledEventParams.iRAContributionDepositTypeIdField,
    iraRolloverTypeId: journal.ScheduledEventParams.iRARolloverTypeIdField,
    iraContributionSourceTypeId: journal.ScheduledEventParams.iRAContributionSourceTypeIdField,
  },
  iraWithdrawal: {
    distributionTypeId: journal.ScheduledEventParams.iRADistributionTypeIdField,
    federalTaxWithholdingPercentage: journal.ScheduledEventParams.fedWithholdingPercentageField,
    iraDistributionReasonTypeId: journal.ScheduledEventParams.iRADistributionReasonIdField,
    iraTypeId: journal.ScheduledEventParams.iRATypeIdField,
    stateTaxWithholdingPercentage: journal.ScheduledEventParams.stateWithholdingPercentageField,
    withholdFederalTaxes: (journal.ScheduledEventParams.fedWithholdingAmountField !== '0' ||
    journal.ScheduledEventParams.fedWithholdingPercentageField !== '0'),
    withholdStateTaxes: (journal.ScheduledEventParams.stateWithholdingAmountField !== '0' ||
    journal.ScheduledEventParams.stateWithholdingPercentageField !== '0'),
    federalTaxWithholdingAmount: journal.ScheduledEventParams.fedWithholdingAmountField,
    stateTaxWithholdingAmount: journal.ScheduledEventParams.stateWithholdingAmountField,
    recharacterizationYear: journal.ScheduledEventParams.iRADistributionYearField,
    directRolloverAccountTypeId: journal.ScheduledEventParams.directRolloverAccountTypeIdField,
    excessContributionYear: journal.ScheduledEventParams.excessContributionYearField,
    isBeforeTaxDeadline: journal.ScheduledEventParams.isBeforeTaxDeadlineField || false,
  },
  fdcnId: journal.ScheduledEventParams.fdcnIdField,
  bankId: journal.ScheduledEventParams.bankIdField,
  isEdit: true,
});

export const wireTransfer = item => {
  return ({
    brokerageAccountNumber: decryption(item.AccountNumber),
    fundsOriginAccountKey: `brokerage_${decryption(item.AccountNumber)}`,
    requestHistoryKey: item.ClientReferenceId,
    destinationBank: `${item.BeneficiaryBankName} - ${
      decryption(item.BeneficiaryBankAccountNumber).slice(-4) || decryption(item.BeneficiaryBankIBAN).slice(-4)
    }`,
    destinationBankName: item.BeneficiaryBankName,
    destinationBankAddress: item.BeneficiaryBankAddress,
    destinationBankCity: item.BeneficiaryBankCity,
    destinationBankState: item.BeneficiaryBankState,
    destinationBankPostalCode: item.BeneficiaryBankPostalCode,
    destinationBankCountry: item.BeneficiaryBankCountry,
    destinationSwiftNumber: item.BeneficiaryBankSwiftNumber || item.BeneficiaryBankABANumber,
    destinationABANumber: item.BeneficiaryBankABANumber,
    destinationAccountTitle: item.BeneficiaryBankAccountTitle,
    destinationAccountNumber: decryption(item.BeneficiaryBankAccountNumber) || decryption(item.BeneficiaryBankIBAN),
    destinationIBAN: decryption(item.BeneficiaryBankIBAN),
    transferAmount: item.Amount,
    transferCurrency: item.CurrencyCode,
    intermediaryNeeded: (item.IntermediaryBankName && item.IntermediaryBankName !== '') || false,
    intermediaryBankName: item.IntermediaryBankName,
    intermediaryBankAddress: item.IntermediaryBankAddress,
    intermediaryBankCity: item.IntermediaryBankCity,
    intermediaryBankState: item.IntermediaryBankState,
    intermediaryBankPostalCode: item.IntermediaryBankPostalCode,
    intermediaryBankCountry: item.IntermediaryBankCountry,
    intermediaryBankSwiftNumber: item.IntermediaryBankSwiftNumber || item.IntermediaryBankABANumber,
    intermediaryBankABANumber: item.IntermediaryBankABANumber,
    intermediaryBankAccountNumber: decryption(item.IntermediaryBankIBAN),
    furtherCreditAccountNumber: decryption(item.FurtherCreditAccountNumber),
    // frequency: '1',
    // calendar: moment(new Date(2019, 3, 10)),
    // isEdit: true,
  });
};

export const wireTransferEdit = item => {
  return ({
    brokerageAccountNumber: decryption(item.AccountNumber),
    fundsOriginAccountKey: `brokerage_${decryption(item.AccountNumber)}`,
    requestHistoryKey: item.ScheduledEventParams.clientReferenceIdField,
    destinationBank: `${item.ScheduledEventParams.beneficiaryBankNameField} - ${
      decryption(item.ScheduledEventParams.beneficiaryBankAccountNumberField).slice(-4) ||
      decryption(item.ScheduledEventParams.beneficiaryBankIBANField).slice(-4)
    }`,
    destinationBankName: item.ScheduledEventParams.beneficiaryBankNameField,
    destinationBankAddress: item.ScheduledEventParams.beneficiaryBankAddressField,
    destinationBankCity: item.ScheduledEventParams.beneficiaryBankCityField,
    destinationBankState: item.ScheduledEventParams.beneficiaryBankStateField,
    destinationBankPostalCode: item.ScheduledEventParams.beneficiaryBankPostalCodeField || '',
    destinationBankCountry: item.ScheduledEventParams.beneficiaryBankCountryField,
    destinationSwiftNumber: item.ScheduledEventParams.beneficiaryBankSwiftNumberField ||
    item.ScheduledEventParams.beneficiaryBankABANumberField,
    destinationABANumber: item.ScheduledEventParams.beneficiaryBankABANumberField,
    destinationAccountTitle: item.ScheduledEventParams.beneficiaryBankAccountTitleField,
    destinationAccountNumber: decryption(item.ScheduledEventParams.beneficiaryBankAccountNumberField) ||
    decryption(item.ScheduledEventParams.beneficiaryBankIBANField),
    destinationIBAN: decryption(item.ScheduledEventParams.beneficiaryBankIBANField),
    transferAmount: Number(item.Amount).toFixed(2),
    transferCurrency: item.ScheduledEventParams.currencyCodeField,
    intermediaryNeeded: (item.ScheduledEventParams.intermediaryBankNameField &&
      item.ScheduledEventParams.intermediaryBankNameField !== '') || false,
    intermediaryBankName: item.ScheduledEventParams.intermediaryBankNameField,
    intermediaryBankAddress: item.ScheduledEventParams.intermediaryBankAddressField,
    intermediaryBankCity: item.ScheduledEventParams.intermediaryBankCityField,
    intermediaryBankState: item.ScheduledEventParams.intermediaryBankStateField,
    intermediaryBankPostalCode: item.ScheduledEventParams.intermediaryBankPostalCodeField || '',
    intermediaryBankCountry: item.ScheduledEventParams.intermediaryBankCountryField,
    intermediaryBankSwiftNumber: item.ScheduledEventParams.intermediaryBankSwiftNumberField ||
    item.ScheduledEventParams.intermediaryBankABANumberField,
    intermediaryBankABANumber: item.ScheduledEventParams.intermediaryBankABANumberField,
    intermediaryBankAccountNumber: decryption(item.ScheduledEventParams.intermediaryBankIBANField),
    furtherCreditAccountTitle: item.ScheduledEventParams.furtherCreditAccountTitleField,
    furtherCreditAccountNumber: decryption(item.ScheduledEventParams.furtherCreditAccountNumberField),
    frequency: item.ScheduledEventParams.scheduledEventFrequencyIDField,
    calendar: moment(item.ScheduledEventParams.startDateField).format('YYYY-MM-DD') === '0001-01-01' ?
      moment() :
      moment(item.ScheduledEventParams.startDateField),
    transferBalanceChosen: item.ScheduledEventParams.accountBalanceField === '1',
    iraWithdrawal: {
      distributionTypeId: item.ScheduledEventParams.iRADistributionTypeIdField,
      federalTaxWithholdingPercentage: item.ScheduledEventParams.fedWithholdingPercentageField,
      iraDistributionReasonTypeId: item.ScheduledEventParams.iRADistributionReasonIdField,
      iraTypeId: item.ScheduledEventParams.iRATypeIdField,
      stateTaxWithholdingPercentage: item.ScheduledEventParams.stateWithholdingPercentageField,
      withholdFederalTaxes: (item.ScheduledEventParams.fedWithholdingAmountField !== '0' ||
      item.ScheduledEventParams.fedWithholdingPercentageField !== '0'),
      withholdStateTaxes: (item.ScheduledEventParams.stateWithholdingAmountField !== '0' ||
      item.ScheduledEventParams.stateWithholdingPercentageField !== '0'),
      federalTaxWithholdingAmount: item.ScheduledEventParams.fedWithholdingAmountField,
      stateTaxWithholdingAmount: item.ScheduledEventParams.stateWithholdingAmountField,
      recharacterizationYear: item.ScheduledEventParams.iRADistributionYearField,
      directRolloverAccountTypeId: item.ScheduledEventParams.directRolloverAccountTypeIdField,
      excessContributionYear: item.ScheduledEventParams.excessContributionYearField,
      isBeforeTaxDeadline: item.ScheduledEventParams.isBeforeTaxDeadlineField || false,
    },
    fdcnId: item.ScheduledEventParams.fdcnIdField,
    isEdit: true,
  });
};
