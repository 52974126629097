/* eslint no-param-reassign: ["error", { "props": false }] */
export const SUBMIT_ACH_TRANSFER_REQUEST = 'SUBMIT_ACH_TRANSFER_REQUEST';
export const SUBMIT_ACH_TRANSFER_SUCCESS = 'SUBMIT_ACH_TRANSFER_SUCCESS';
export const SUBMIT_ACH_TRANSFER_ERROR = 'SUBMIT_ACH_TRANSFER_ERROR';
export const FETCH_ACH_TRANSFER_REQUEST = 'FETCH_ACH_TRANSFER_REQUEST';
export const FETCH_ACH_TRANSFER_SUCCESS = 'FETCH_ACH_TRANSFER_SUCCESS';
export const FETCH_ACH_TRANSFER_ERROR = 'FETCH_ACH_TRANSFER_ERROR';

export function submitAchTransfer(data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [SUBMIT_ACH_TRANSFER_REQUEST, SUBMIT_ACH_TRANSFER_SUCCESS, SUBMIT_ACH_TRANSFER_ERROR],
    fetch: {
      url: '/Accounts/AchTransfers',
      options: { method: 'post', body: JSON.stringify(data) },
    },
  };
}

export function fetchAchTransfer(requestId) {
  return {
    types: [FETCH_ACH_TRANSFER_REQUEST, FETCH_ACH_TRANSFER_SUCCESS, FETCH_ACH_TRANSFER_ERROR],
    fetch: {
      url: `/ScheduledEvents/GetScheduledEventDetails/${requestId}`,
    },
  };
}
