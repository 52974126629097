export const FETCH_BROKERAGE_ACCOUNTS_REQUEST = 'FETCH_BROKERAGE_ACCOUNTS_REQUEST';
export const FETCH_BROKERAGE_ACCOUNTS_SUCCESS = 'FETCH_BROKERAGE_ACCOUNTS_SUCCESS';
export const FETCH_BROKERAGE_ACCOUNTS_ERROR = 'FETCH_BROKERAGE_ACCOUNTS_ERROR';

export function requestBrokerageAccounts() {
  return {
    types: [FETCH_BROKERAGE_ACCOUNTS_REQUEST, FETCH_BROKERAGE_ACCOUNTS_SUCCESS, FETCH_BROKERAGE_ACCOUNTS_ERROR],
    fetch: {
      url: '/Users/Accounts',
    },
  };
}
