export const FETCH_HISTORY_REQUEST = 'FETCH_HISTORY_REQUEST';
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_ERROR = 'FETCH_HISTORY_ERROR';

export function fetchHistory() {
  const queryParameters = '$filter=TransferType ne \'ACH Relation\'&$orderby=RequestReceivedDate desc';
  return {
    types: [FETCH_HISTORY_REQUEST, FETCH_HISTORY_SUCCESS, FETCH_HISTORY_ERROR],
    fetch: {
      url: `/Users/Transactions?${queryParameters}`,
    },
  };
}
