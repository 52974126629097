import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import authenticationReducer from './authenticationReducer';
import lookupReducer from './lookupReducer';
import brokerageAccountReducer from './brokerageAccountReducer';
import bankAccountReducer from './bankAccountReducer';
import addBankAccountReducer from './addBankAccountReducer';
import wireTransferWithdrawalReducer from './wireTransferWithdrawalReducer';
import requestHistoryReducer from './requestHistoryReducer';
import cloneRequestReducer from './cloneRequestReducer';
import transferFeeReducer from './transferFeeReducer';
import fastlinkReducer from './fastlinkReducer';
import preloaderReducer from './preloaderReducer';
import activityBarReducer from './activityBarReducer';
import errorBarReducer from './errorBarReducer';
import countriesReducer from './countriesReducer';
import amountReducer from './amountReducer';
import currencyReducer from './currencyReducer';
import scheduledDateReducer from './scheduledDateReducer';
import modalReducer from './modalReducer';
import scheduledTransferReducer from './scheduledTransferReducer';
import scheduledTransferDetailsReducer from './scheduledTransferDetailsReducer';
import bankingDayReducer from './bankingDayReducer';
import frequencyReducer from './frequencyReducer';
import periodReducer from './periodReducer';
import encryptionReducer from './encryptionReducer';
import userProfileReducer from './userProfileReducer';

const keepInitialState = state => state || {};
export default combineReducers({
  configuration: keepInitialState,
  clientId: keepInitialState,
  userId: keepInitialState,
  clientConfig: keepInitialState,
  authentication: authenticationReducer,
  lookup: lookupReducer,
  brokerageAccounts: brokerageAccountReducer,
  bankingAccounts: bankAccountReducer,
  wireTransfers: wireTransferWithdrawalReducer,
  linkAccounts: addBankAccountReducer,
  requestHistory: requestHistoryReducer,
  cloneRequest: cloneRequestReducer,
  transferFees: transferFeeReducer,
  fastlink: fastlinkReducer,
  form: formReducer,
  preloader: preloaderReducer,
  activityBar: activityBarReducer,
  errorBar: errorBarReducer,
  routing: routerReducer,
  countries: countriesReducer,
  amount: amountReducer,
  currency: currencyReducer,
  schedule: scheduledDateReducer,
  modal: modalReducer,
  scheduledTransfers: scheduledTransferReducer,
  scheduledTransferDetails: scheduledTransferDetailsReducer,
  bankingDays: bankingDayReducer,
  frequency: frequencyReducer,
  period: periodReducer,
  encryption: encryptionReducer,
  userProfile: userProfileReducer,
});
