import React from 'react';
import { Route, Switch } from 'react-router';
import AccountOpeningLandingPage from './components/AOP/AccountOpeningLandingPage';
import SmartTransferPage from './components/SmartTransfer/SmartTransferPage';
import ThankYouPage from './components/ThankYouPage';
import ManageAccountsPage from './components/ManageAccounts/ManageAccountsPage';
import AutomaticLinkingPage from './components/AutomaticLinking/AutomaticLinkingPage';
import IncompleteInfoPage from './components/IncompleteInfo/IncompleteInfoPage';
import AddBankAccountThankYouPage from './components/AddBankAccountThankYou/AddBankAccountThankYouPage';
import RequestHistoryPage from './components/RequestHistory/RequestHistoryPage';
import CheckTransferDepositPage from './components/CheckTransferDeposit/CheckTransferDepositPage';
import CheckTransferWithdrawalPage from './components/CheckTransferWithdrawal/CheckTransferWithdrawalPage';
import WireTransferDepositPage from './components/WireTransferDeposit/WireTransferDepositPage';
import WireTransferWithdrawalPage from './components/WireTransferWithdrawal/WireTransferWithdrawalPage';
import ACATSDepositPage from './components/ACATS/ACATSDepositPage';
import FundingMethodsPage from './components/FundingMethods/FundingMethodsPage';
import LandingPage from './components/LandingPage/LandingPage';
import ErrorPage from './components/ErrorPage';
import MaintenancePage from './components/MaintenancePage/MaintenancePage';

export const StaticRoutes = {
  ROOT: '/',
  LOGIN_REQUIRED: '/login-required',
  AOP: '/aop',
  TRANSFERS: '/transfers',
  THANK_YOU: '/transfers/confirmation',
  MANAGE_BANK_ACCOUNTS: '/external/',
  LINK_BANK_ACCOUNT_AUTOMATIC: '/external/add-automatic',
  LINK_BANK_ACCOUNT_THANK_YOU: '/external/confirmation',
  HISTORY: '/history',
  CHECK_TRANSFER_IN: '/transfers/check/in',
  CHECK_TRANSFER_OUT: '/transfers/check/out',
  WIRE_TRANSFER_IN: '/transfers/wire/in',
  WIRE_TRANSFER_OUT: '/transfers/wire/out',
  ACATS_IN: '/transfers/acats/in',
  INTERNAL_TRANSFER: '/internal',
  FUNDING_METHODS: '/funding-methods',
  MAINTENANCE: '/maintenance',
};

export const AbsoluteRoutes = {
  CUSTOMER_ACCOUNT_TRANSFER: 'https://www.tradestation.com/single-forms/customer-account-transfer/',
  ONLINE_TRANSFER_ACH_AUTH: 'https://www.tradestation.com/single-forms/online-transfer-and-ach-authorization/',
  CHECK_REQUEST: 'https://www.tradestation.com/single-forms/check-request/',
};

export const DynamicRoutes = {
  linkBankAccountIncomplete: (accountId) => `/external/add-automatic/${accountId}`,
  wireTransferOut: (requestId) => `/transfers/wire/out/${requestId}`,
  checkTransferOut: (requestId) => `/transfers/check/out/${requestId}`,
  cashACH: (requestId) => `/transfers/smart/${requestId}`,
  internalTransfer: (requestId) => `/transfers/journal/${requestId}`,
};

const Routes = () => {
  return (
    <Switch>
      <Route path={StaticRoutes.MAINTENANCE} component={MaintenancePage} />
      <Route exact path={StaticRoutes.ROOT} component={LandingPage} />
      <Route path={StaticRoutes.AOP} component={AccountOpeningLandingPage} />
      <Route exact path={StaticRoutes.TRANSFERS} component={SmartTransferPage} />
      <Route path={DynamicRoutes.cashACH(':requestId')} component={SmartTransferPage} />
      <Route path={StaticRoutes.THANK_YOU} component={ThankYouPage} />
      <Route exact path={StaticRoutes.MANAGE_BANK_ACCOUNTS} component={ManageAccountsPage} />
      <Route exact path={StaticRoutes.LINK_BANK_ACCOUNT_AUTOMATIC} component={AutomaticLinkingPage} />
      <Route path={DynamicRoutes.linkBankAccountIncomplete(':accountId')} component={IncompleteInfoPage} />
      <Route path={StaticRoutes.LINK_BANK_ACCOUNT_THANK_YOU} component={AddBankAccountThankYouPage} />
      <Route path={StaticRoutes.HISTORY} component={RequestHistoryPage} />
      <Route path={StaticRoutes.CHECK_TRANSFER_IN} component={CheckTransferDepositPage} />
      <Route exact path={StaticRoutes.CHECK_TRANSFER_OUT} component={CheckTransferWithdrawalPage} />
      <Route path={DynamicRoutes.checkTransferOut(':requestId')} component={CheckTransferWithdrawalPage} />
      <Route path={StaticRoutes.WIRE_TRANSFER_IN} component={WireTransferDepositPage} />
      <Route exact path={StaticRoutes.WIRE_TRANSFER_OUT} component={WireTransferWithdrawalPage} />
      <Route path={DynamicRoutes.wireTransferOut(':requestId')} component={WireTransferWithdrawalPage} />
      <Route path={StaticRoutes.ACATS_IN} component={ACATSDepositPage} />
      <Route path={StaticRoutes.FUNDING_METHODS} component={FundingMethodsPage} />
      <Route path={DynamicRoutes.internalTransfer(':requestId')} component={SmartTransferPage} />
      [this must be the last line]
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default Routes;
