import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, destroy } from 'redux-form';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';
import { StaticRoutes } from '../../Routes';
import * as brokerageAccountActions from '../../actions/brokerageAccountActions';
import * as transferFeeActions from '../../actions/transferFeeActions';
import * as ach from '../../actions/achTransferActions';
import * as bankAccountActions from '../../actions/bankAccountActions';
import * as lookupActions from '../../actions/lookupActions';
import * as cloneActions from '../../actions/cloneRequestActions';
import * as internal from '../../actions/internalTransferActions';
import * as scheduledTransferActions from '../../actions/scheduledTransferActions';
import SmartTransferForm from './SmartTransferForm';
import SmartTransferConfirmation from './SmartTransferConfirmation';
import { changeAmount } from '../../actions/amountActions';
import { changeDate } from '../../actions/scheduledDateActions';
import './SmartTransfer.css';
import { changeCurrency } from '../../actions/currencyActions';
import { changeFrequency } from '../../actions/frequencyActions';
import { encryption } from '../../components/Encryption/EncryptionDecryptionPage';

/* eslint-disable */
const normalize = (value) => {
  if (!value || !isNaN(value)) return value;
  const cleanValue = parseFloat(value.replace(/\./g, '').replace(/,/g, ''));
  const result = (cleanValue / 100).toFixed(2);
  return Number(result);
}

class SmartTransferPage extends Component {
  componentWillUnmount() {
    this.props.destroyForm();
  }

  render() {
    const { confirming } = this.props.formValues.values;
    return (
      <div className="page">
        {confirming ?
          <SmartTransferConfirmation
            {...this.props}
            onSubmit={this.props.onSubmitTransfer}
          /> :
          <SmartTransferForm
            {...this.props}
            onSubmit={this.props.onConfirmRequested}
          />}
      </div>
    );
  }
}

SmartTransferPage.propTypes = {
  destroyForm: PropTypes.func.isRequired,
  onSubmitTransfer: PropTypes.func.isRequired,
  onConfirmRequested: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      confirming: PropTypes.bool.isRequired,
    }),
  }),
};
  
const formName = 'cashACHForm';

const initialValues = {
  transferCurrency: 'USD',
  transferAmount: '0.00',
  confirming: false,
  previousSelectedDestinationAccount: null,
  isEdit: false,
};

const DEBIT = 1;
const CREDIT = 2;

export default connect(
  (state) => {
    const form = state.form.cashACHForm || {};

    initialValues.transferCurrency = state.currency.code;

    form.values = Object.assign({ }, initialValues, form.values);

    const brokerageAccounts = state.brokerageAccounts.data;
    const bankAccounts = state.bankingAccounts.data;
    const originAccounts = brokerageAccounts.concat(bankAccounts);

    const selectedOriginAccount = originAccounts
      .find(account => account.key === form.values.fundsOrigin);

    const linkedAccounts = selectedOriginAccount ? selectedOriginAccount.linkedAccounts : { bank: [], brokerage: [] };

    const destinationBrokerageAccounts = brokerageAccounts
      .filter(account => linkedAccounts.brokerage.includes(account.accountNumber));

    const destinationBankAccounts = bankAccounts
      .filter(account => linkedAccounts.bank.includes(account.bankId));

    const destinationAccounts = destinationBrokerageAccounts.concat(destinationBankAccounts);

    const selectedDestinationAccount = originAccounts
      .find(account => account.key === form.values.fundsDestination);

    let distributionTypeId = form.values.iraWithdrawal && form.values.iraWithdrawal.distributionTypeId;
    const totalDistributionId = state.lookup.identifiers.IRADistributionType.TotalDistribution;

    const isJournal = selectedOriginAccount && selectedOriginAccount.isBrokerageAccount &&
      selectedDestinationAccount && selectedDestinationAccount.isBrokerageAccount;

    const showWithdrawAll = selectedOriginAccount &&
      (selectedOriginAccount.isBrokerageAccount && !selectedOriginAccount.isIRA);

    const transferBalanceChosen = showWithdrawAll && form.values.transferBalanceChosen;

    const supportedCurrencies = (selectedDestinationAccount && selectedDestinationAccount.isFuturesAccount &&
      selectedOriginAccount && selectedOriginAccount.supportedCurrencies) ||
      // ['USD'];
      [initialValues.transferCurrency];

    // const supportedCurrencies = (selectedOriginAccount && selectedOriginAccount.supportedCurrencies) 
    //  || [initialValues.transferCurrency];

    const frequencyOptions = state.lookup.RecurrenceSchedule;
    let frequencyTooltipOptions = '';

    frequencyOptions.forEach(frequency => {
      frequencyTooltipOptions +=
      `<p><strong>${frequency.Value}: </strong>${frequency.Description}</p>`;
    });

    const oneTimeTransferId = state.lookup.identifiers.RecurrenceSchedule['One Time'];
    const frequency = state.frequency.key ? state.frequency.key : oneTimeTransferId;
    initialValues.frequency = frequency;
    const isOneTimeTransfer = frequency
    && frequency.toString() === oneTimeTransferId;

    if (selectedOriginAccount && !selectedOriginAccount.isIRA) {
      distributionTypeId = '';
    }

    const transferAccountBalance = transferBalanceChosen || distributionTypeId === totalDistributionId;

    let amount = state.amount;
    if (normalize(form.values.transferAmount) !== 0 && amount.current !== normalize(form.values.transferAmount)) {
      amount = {
        current: Number(form.values.transferAmount),
      };
    }
    if (selectedOriginAccount && selectedOriginAccount.isIRA && selectedOriginAccount.isFuturesAccount) {
      amount = {
        current: 0,
      };
    }

    let allCashAmount = 0;
    if (transferAccountBalance) {
      allCashAmount = selectedOriginAccount.availableToWithdraw
        .find((x) => x.Currency === form.values.transferCurrency).Realtime;
    }

    const bankingDays = state.bankingDays.data;
    const scheduledDate = state.schedule.scheduledDate || moment.min(bankingDays);

    return {
      formValues: form,
      originAccounts,
      destinationAccounts,
      selectedOriginAccount,
      selectedDestinationAccount,
      supportedCurrencies,
      lookup: state.lookup,
      cloneRequest: state.cloneRequest,
      linkedAccount: state.linkAccounts.linkedAccount,
      showWithdrawAll,
      transferAccountBalance,
      isJournal,
      amount,
      allCashAmount,
      isWireTransferEnabled: state.clientConfig.isWireTransferEnabled,
      isCheckTransferEnabled: state.clientConfig.isCheckTransferEnabled,
      frequencyOptions,
      frequencyTooltipOptions,
      frequency,
      scheduledDate,
      bankingDays,
      isOneTimeTransfer,
    };
  }, (dispatch) => {
    let isEdit = false;
    return {
      onInitializeForm: (requestId) => {
        const actions = [
          dispatch(brokerageAccountActions.requestBrokerageAccounts()),
          dispatch(bankAccountActions.requestBankingAccounts()),
          dispatch(lookupActions.fetchLookup()),
          dispatch(transferFeeActions.fetchFees()),
          dispatch(changeDate(null)),
        ];

        if (requestId) {
          actions.push(dispatch(ach.fetchAchTransfer(requestId)));
        }

        Promise.all(actions);
      },
      setNewlyLinkedBank: (linkedAccount, form) => {
        if (linkedAccount) {
          dispatch(change(form, 'fundsOrigin', linkedAccount.key));
          dispatch(change(form, 'originAccountIdentifier', linkedAccount.bankId));
          dispatch(bankAccountActions.cleanupLinkBankAccountWorkflow());
        }
      },
      onSelectOriginAccount: (accountIdentifier, form) => {
        dispatch(change(form, 'transferCurrency', 'USD'));

        dispatch(change(form, 'originAccountIdentifier', accountIdentifier));
        dispatch(change(form, 'fundsDestination', null));
      },
      onSelectDestinationAccount: (accountIdentifier, form) => {
        dispatch(change(form, 'destinationAccountIdentifier', accountIdentifier));
      },
      onAddBankAccount: () => {
        dispatch(bankAccountActions.startLinkBankAccountWorkflow(StaticRoutes.TRANSFERS));
        dispatch(push(StaticRoutes.LINK_BANK_ACCOUNT_AUTOMATIC));
      },
      selectPreviousRequest: (selectedRequest, form) => {
        Object.keys(selectedRequest).forEach(key =>
          dispatch(change(form, key, selectedRequest[key]))
        );

        dispatch(cloneActions.removeCloneRequest());
      },
      onConfirmRequested: (_, __, props) => {
        dispatch(change(props.form, 'confirming', true));
        window.scrollTo(0, 0);
      },
      onConfirmCanceled: (form) => {
        isEdit = true;
        dispatch(change(form, 'confirming', false));
        window.scrollTo(0, 0);
      },
      changeAmount: (amount) => {
        dispatch(changeAmount(amount));
      },
      changeScheduledDate: (date) => {
        dispatch(changeDate(date));
      },
      changeCurrency: (currency, form) => {
        dispatch(change(form, 'transferCurrency', currency));
        dispatch(changeCurrency(currency));
      },
      changeFrequency: (frequency, form) => {
        dispatch(change(form, 'frequency', frequency));
        dispatch(changeFrequency(frequency));
      },
      onSubmitTransfer: (values, _, props) => {
        const accountNumber = values.fundsOrigin.startsWith('brokerage') ?
        values.originAccountIdentifier :
        values.destinationAccountIdentifier;
        const submitData = props.isJournal ?
          {
            accountNumber: encryption(accountNumber),
            toAccountNumber: encryption(values.fundsDestination.split('brokerage_').pop()),
            amount: values.transferAmount,
            currencyCode: values.transferCurrency,
            transferAccountBalance: props.transferAccountBalance,
          } :
          {
            accountNumber: encryption(accountNumber),
            bankId: values.fundsOrigin.startsWith('bank')
              ? values.originAccountIdentifier
              : values.destinationAccountIdentifier,
            amount: values.transferAmount,
            currencyCode: values.transferCurrency,
            direction: values.fundsOrigin.startsWith('brokerage') ? DEBIT : CREDIT,
            transferAccountBalance: props.transferAccountBalance,
          };

        submitData.iraContribution = values.iraDeposit;
        submitData.iraDistribution = values.iraWithdrawal;

        submitData.scheduledEventFrequencyID = values.frequency;
        submitData.startDate = props.isOneTimeTransfer ?
          props.scheduledDate.format('MM/DD/YYYY') : submitData.startDate;


        // const accountNumberToBody = values.fundsOrigin.startsWith('brokerage') ?
        // encryption(values.originAccountIdentifier) :
        // encryption(values.destinationAccountIdentifier);

        let promise = null;
        if (props.formValues.values.isEdit) {
          // Here we will call the api endpoint for updating scheduled transfer. Next 3 lines should change when knowing the api endpoint for updating scheduleds
          submitData.iraContribution = props.selectedDestinationAccount.isIRA ? values.iraDeposit : null;
          submitData.iraDistribution = props.selectedOriginAccount.isIRA ? values.iraWithdrawal : null;
          submitData.fdcnId = values.fdcnId;
          submitData.cashAmount = submitData.amount;
          submitData.accountNumber = encryption(values.accountNumber);
          submitData.direction = values.fundsOrigin.startsWith('brokerage') ? 'Debit' : 'Credit';
          submitData.bankId = values.bankId;
          submitData.fromAccountNumber = encryption(values.accountNumber);
          const scheduledEventId = props.match.params.requestId;
          promise = props.isJournal ?
            dispatch(scheduledTransferActions.updateJournalScheduledTransfer(scheduledEventId, submitData)) :
            dispatch(scheduledTransferActions.updateAchScheduledTransfer(scheduledEventId, submitData));
        } else {
          promise = props.isJournal ?
          // TODO: ENCRYPT VALUE OR CHANGE THE AMOUNT OF PARAMETERS AND INCLUDE THE ACCOUNTNUMBER IN THE SUBMITDATA TO AVOID QUERY STRING
            dispatch(internal.submitInternalTransfer(submitData)) :
            dispatch(ach.submitAchTransfer(submitData));
        }

        promise
          .then(action => {
            if (action.isSuccess) {
              dispatch(scheduledTransferActions.fetchScheduledTransfers());
              dispatch(push(StaticRoutes.THANK_YOU));
            }
          });
      },
      destroyForm: () => {
        dispatch(destroy(formName));
      },
    };
  })(SmartTransferPage);
