import api from './api';
import MockApi from './apiMock';
import handleErrors from './handleErrors';

function isRequest({ types, fetch }) {
  return Array.isArray(types) && types.length === 3 &&
    fetch && fetch.url;
}

const handleJson = response => {
  const contentType = response.headers.get('content-type');
  const isJson = contentType && contentType.includes('application/json');
  return isJson ? response.json() : response;
};

function makeRequest(store, { fetch, types, ...rest }, next) {
  const [REQUEST, SUCCESS, ERROR] = types;

  next({ ...rest, type: REQUEST, fetch, isRequest: true }); // Dispatch your request action

  const state = store.getState();
  const config = state.configuration;
  const authToken = state.authentication.authToken;
  const options = { api: fetch.api || 'carma', secured: !fetch.unsecured };
  const promise = authToken === '1234' ? MockApi.handle(options.api, config) : api(options, config, authToken);

  return promise(fetch.url, fetch.options)
    .then(response => handleErrors(response, state.clientId, state.userId))
    .then(handleJson)
    .then(data => ({ ...rest, payload: data, type: SUCCESS, fetch, isSuccess: true })) // Your success action
    .catch(error => ({
      ...rest,
      error,
      type: ERROR,
      fetch,
      // do not show <ErrorBar /> due to Plaid logs issues
      isError: !(fetch.api === 'carma' && fetch.url.endsWith('plaid/log')),
    })) // Your failure action
    .then(action => {
      next(action); // Dispatch
      return action;
    });
}

const requestThunk = store => next => action =>
  (isRequest(action) ?
    makeRequest(store, action, next) :
    next(action));

export default requestThunk;
