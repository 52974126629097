import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment-timezone';
import { StaticRoutes } from '../../Routes';
import RenderDropdownField from '../Forms/RenderDropdownField';
import FrequencyFormField from '../Forms/FrequencyFormField';
import RenderTextField from '../Forms/RenderTextField';
import { isInvalidATW } from '../ATW/validations';
import Header from '../Header';
import { TransferMethod } from '../DropdownRouter';
import RippleButton from '../Forms/RippleButton';
import IRAContributionForm from '../IRA/IRAContributionForm';
import IRADistributionForm from '../IRA/IRADistributionForm';
import IRACustodianAccount from '../IRA/IRACustodianAccount';
import IRAFees from '../Fees/IRAFees';
import DonutChart from '../ATW/DonutChart';
import RenderDatePickerField from '../Forms/RenderDatePickerField';

const AmountErrorMessage = 'Amount must be greater than 0 and less than Available To Withdraw';
const TaxAmountsGreaterThanTransferAmountErrorMessage = 'Tax withholding exceeds requested amount';


const SelectAccountFormField = (props) => {
  const bankAccounts = props.accounts.filter(account => account.isBankAccount);
  return (
    <Field
      {...props}
      component={RenderDropdownField}
      id={props.id}
      name={props.name}
      label={props.label}
      onChange={props.onChange}
      validationMessage="Please select an account from the list."
      required
    >
      <option />
      {props.askForBankAccount &&
        <optgroup label="External Accounts">
          {bankAccounts
            .map((account) =>
              <option
                key={account.key}
                data-identifier={account.bankId}
                value={account.key}
                id={account.key}
              >
                {account.displayName}
              </option>
            )}
          <option value="add" style={{ color: '#0077BF', fontWeight: 'bolder' }}> {/* eslint-disable-line */}
            &#xFF0B;Add an account
          </option>
        </optgroup>}
      <optgroup label="TradeStation Accounts">
        {props.accounts.filter((account) => account.isBrokerageAccount === true)
          .map((account) =>
            <option
              key={account.key}
              data-accounttype="brokerageAccount"
              data-brokeragetype={account.accountDetail}
              data-identifier={account.accountNumber}
              value={account.key}
              id={account.key}
            >
              {account.displayName}
            </option>
          )}
      </optgroup>
    </Field>
  );
};

SelectAccountFormField.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  askForBankAccount: PropTypes.bool,
};

SelectAccountFormField.defaultProps = {
  askForBankAccount: true,
};

class SmartTransferForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      redraw: false,
      alreadyDrawn: false,
    };
    this.selectAccount = this.selectAccount.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateAmounts = this.validateAmounts.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.normalize = this.normalize.bind(this);
    this.formatAmount = this.formatAmount.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleFrequencyChange = this.handleFrequencyChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.getSelectedFrequencyTooltipHtml = this.getSelectedFrequencyTooltipHtml.bind(this);
  }

  componentDidMount() {
    if (!this.props.formValues.anyTouched) {
      this.props.onInitializeForm(this.props.match.params.requestId);
      this.props.setNewlyLinkedBank(this.props.linkedAccount, this.props.form);
    }
  }

  componentWillReceiveProps() {
    this.setState({
      error: '',
      alreadyDrawn: true,
    }, () => {
      if (this.state.alreadyDrawn) {
        this.setState({
          redraw: false,
          alreadyDrawn: false,
        });
      }
      if (this.props.selectedOriginAccount && this.props.selectedOriginAccount.isBrokerageAccount &&
        !this.props.selectedOriginAccount.isFuturePlus) {
        const today = moment();
        const atwAmount = this.props.selectedOriginAccount.availableToWithdraw
          .find((x) => x.Currency === this.props.formValues.values.transferCurrency);

        if (!atwAmount) {
          this.setState({
            error: 'It was not possible to process the selected currency. Please try another one',
          });
        } else if (this.props.amount.current < 0 ||
          (this.props.amount.current > atwAmount.Realtime &&
            this.props.isOneTimeTransfer &&
            today.format('YYYY-MM-DD') === this.props.scheduledDate.format('YYYY-MM-DD'))) {
          this.setState({
            error: AmountErrorMessage,
          });
        } else {
          this.setState({
            error: '',
          });
        }
      }
    });
  }

  componentDidUpdate() {
    if (Object.keys(this.props.cloneRequest).length > 0) {
      const originAccount = this.props.originAccounts.find(
        account => account.key === this.props.cloneRequest.fundsOrigin);

      const destinationAccount = this.props.originAccounts.find(
        account => account.key === this.props.cloneRequest.fundsDestination);

      this.props.changeFrequency(this.props.cloneRequest.frequency, this.props.form);
      this.props.changeScheduledDate(this.props.cloneRequest.calendar);

      this.props.selectPreviousRequest(this.props.cloneRequest, this.props.form, originAccount, destinationAccount);
    }
  }

  getSelectedFrequencyTooltipHtml() {
    let selectedFrequencyTooltipHtml = '';
    const selectedFrequencyKey = this.props.frequency;
    if (selectedFrequencyKey) {
      const selectedFrequency = this.props.frequencyOptions.find((frequencyObject) =>
        selectedFrequencyKey && frequencyObject.Key.toString() === selectedFrequencyKey);
      selectedFrequencyTooltipHtml =
        <p><strong>{selectedFrequency.Value}: </strong>{selectedFrequency.Description}</p>;
    }
    return selectedFrequencyTooltipHtml;
  }

  handleChange(event) {
    let amount = event.target.value;
    if (!this.props.selectedOriginAccount) {
      return;
    }

    if (!event.target.value) {
      amount = 0;
    }

    this.setState({
      redraw: false,
    });

    this.props.changeAmount(amount);
  }

  handleDateChange(event) {
    this.props.changeScheduledDate(event);
  }

  handleCurrencyChange(event) {
    if (event.target.value) {
      this.props.changeCurrency(event.target.value, this.props.form);
    }
  }

  handleFrequencyChange(event) {
    if (event.target.value === '') {
      event.preventDefault();
      return;
    }
    if (event.target.value) {
      this.props.changeFrequency(event.target.value, this.props.form);
    }
  }

  selectAccount(event) {
    if (event.target.value === 'add') {
      this.props.onAddBankAccount();
    } else if (event.target.name === 'fundsOrigin') {
      this.setState({
        redraw: true,
      });
      const accountIdentifier = event.target.options[event.target.selectedIndex].dataset.identifier;
      this.props.onSelectOriginAccount(accountIdentifier, this.props.form);
    } else if (event.target.name === 'fundsDestination') {
      const accountIdentifier = event.target.options[event.target.selectedIndex].dataset.identifier;
      this.props.changeCurrency('USD', this.props.form);
      this.props.onSelectDestinationAccount(accountIdentifier, this.props.form);
    }
  }

  // Validate that the combination of Tax Amounts is not greater then Trasnfer Amount
  validateAmounts(formValues, amount) {
    // get Amounts
    let IRAFederalAmount = parseFloat(formValues.iraWithdrawal &&
      formValues.iraWithdrawal.federalTaxWithholdingAmount ?
      this.normalize(formValues.iraWithdrawal.federalTaxWithholdingAmount) : 0);
    let IRAStateAmount = parseFloat(formValues.iraWithdrawal &&
      formValues.iraWithdrawal.stateTaxWithholdingAmount ?
      this.normalize(formValues.iraWithdrawal.stateTaxWithholdingAmount) : 0);

    // get percentanges
    const IRAStatePercent = parseFloat(formValues.iraWithdrawal &&
      formValues.iraWithdrawal.stateTaxWithholdingPercentage ?
      this.normalize(formValues.iraWithdrawal.stateTaxWithholdingPercentage) : 0);
    const IRAFederalPercent = parseFloat(formValues.iraWithdrawal &&
      formValues.iraWithdrawal.federalTaxWithholdingPercentage ?
      this.normalize(formValues.iraWithdrawal.federalTaxWithholdingPercentage) : 0);


    /* We are gonna use the IRAmounts to validate that the resulting amount
    on the Taxes info is not greater than the transfer amount.
    At any point in time on the tax fields we will have either plain amount or
    a percent  but not the two at the same time ,
    if we have percentage instead of tax amount ,
    we calculate the tax amount ,
    this way we always use amounts for the validation and its easier to understand the validation */
    if (IRAStatePercent > 0) {
      IRAStateAmount = (amount / 100) * IRAStatePercent;
    }

    if (IRAFederalPercent > 0) {
      IRAFederalAmount = (amount / 100) * IRAFederalPercent;
    }

    return amount <= (IRAStateAmount + IRAFederalAmount);
  }

  // iOS Safari pre 10.3.3 won't call checkValidity before a form submission, so we have to do this
  // ourselves because we depend on native html validation to prevent form submission errors
  /* eslint-disable */
  handleSubmit(event) {
    event.preventDefault();
    const amount = this.props.transferAccountBalance ? this.props.allCashAmount : this.normalize(this.props.formValues.values.transferAmount);

    const isTaxAmountsGreaterThanTransferAmount = this.validateAmounts(this.props.formValues.values, parseFloat(amount));
      
    if (!this.props.selectedOriginAccount.isFuturePlus && (!event.target.checkValidity() || isInvalidATW(this.props.selectedOriginAccount, this.props.formValues.values.transferCurrency, this.props.formValues.values.frequency, this.props.scheduledDate) || amount <= 0))  { 
      event.preventDefault();
      this.setState({
        error: AmountErrorMessage,
      });
    } else if (isTaxAmountsGreaterThanTransferAmount) {
        event.preventDefault();
        this.setState({
          error: TaxAmountsGreaterThanTransferAmountErrorMessage,
        });
      }
    else {
      this.setState({
        error: '',
      });
      this.props.handleSubmit(event);
    }
  }

  /* eslint-disable */
  formatAmount(value) {
    if (!value) return value;
    const cleanValue = parseFloat(value.replace(/\./g, '').replace(/,/g, ''));
    const newValue = (cleanValue / 100);

    const result = Number(newValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    
    return result;
  }

  /* eslint-disable */
  normalize(value) {
    if (!value || !isNaN(value)) return value;
    const cleanValue = parseFloat(value.replace(/\./g, '').replace(/,/g, ''));
    const result = (cleanValue / 100).toFixed(2);
    return result;
  }

  render() {
    const {
      selectedOriginAccount,
      selectedDestinationAccount,
      supportedCurrencies,
      isJournal,
      showWithdrawAll,
      amount,
      allCashAmount,
      transferAccountBalance,
      formValues,
      isWireTransferEnabled,
      isCheckTransferEnabled,
      scheduledDate,
      bankingDays,
      frequencyOptions,
      frequencyTooltipOptions,
      match,
    } = this.props;
    return (
      <form id="cash-ach-form" onSubmit={this.handleSubmit}>
        <Header
          title="Transfer Cash"
          description="Transfer cash assets to or from your linked bank account or another TradeStation account"
        />
        <section>
          <TransferMethod
            onChange={this.handleChange}
            defaultValue={StaticRoutes.TRANSFERS}
            isWireTransferEnabled={isWireTransferEnabled}
            isCheckTransferEnabled={isCheckTransferEnabled}
          />
          <div className="fee-schedule">
            <div id="fees">
              <dl>
                <dt>Cost:</dt>
                <dd>Free</dd>  
              </dl>
              {selectedOriginAccount && selectedOriginAccount.isIRA &&
                <IRAFees
                  fundsOriginAccount={selectedOriginAccount}
                />
              }
            </div>
            <span className="separator" />
            <dl>
              <dt>Max:</dt>
              {isJournal ? <dd></dd> : <dd>$50,000</dd>}
            </dl>
            <span className="separator" />
            <dl>
              <dt>Availability:</dt>
              <dd>{isJournal ? "1 Bus. Day": "3 Bus. Days"}</dd>
            </dl>
          </div>
          <SelectAccountFormField
            id="fundsOrigin"
            name="fundsOrigin"
            label="From"
            onChange={this.selectAccount}
            accounts={this.props.originAccounts}
            askForBankAccount
            disabled={match.params.requestId ? true : false}
          />
          {selectedOriginAccount && selectedOriginAccount.isBrokerageAccount &&
            <div>
             {!selectedOriginAccount.isFuturePlus &&
              <DonutChart
                selectedOriginAccount={selectedOriginAccount}
                amount={(transferAccountBalance ? allCashAmount : amount.current)}
                currency={formValues.values.transferCurrency}
                redraw={this.state.redraw}
              />
             }
            </div>
          }
          {selectedOriginAccount
           && (
              (selectedOriginAccount.isIRA && selectedOriginAccount.isFuturesAccount)
              || selectedOriginAccount.IsCustodian
           )
            ? <IRACustodianAccount />
            : <div>
              {selectedOriginAccount &&
                <IRADistributionForm
                  {...this.props}
                  account={selectedOriginAccount}
                />}
              <SelectAccountFormField
                name="fundsDestination"
                id="fundsDestination"
                label="To"
                onChange={this.selectAccount}
                accounts={this.props.destinationAccounts}
                askForBankAccount={!selectedOriginAccount || !selectedOriginAccount.isBankAccount}
                disabled={!selectedOriginAccount || (match.params.requestId ? true : false)}
              />
              {selectedDestinationAccount
                && (
                  (selectedDestinationAccount.isIRA && selectedDestinationAccount.isFuturesAccount)
                  || selectedDestinationAccount.IsCustodian
                )
                ? <IRACustodianAccount />
                : <div>
                  {selectedDestinationAccount &&
                    <IRAContributionForm
                      {...this.props}
                      account={selectedDestinationAccount}
                    />}
                  <div className="amount">
                    {this.props.transferAccountBalance &&
                      <div className="field text is-dirty">
                        <label htmlFor="transferBalance">Amount</label>
                        <input
                          type="text"
                          name="transferBalance"
                          id="transferBalance"
                          value="All Available Cash"
                          disabled
                        />
                        <span className="expanding-line" />
                        <span className="validation">{!selectedOriginAccount.isFuturePlus && selectedOriginAccount.availableToWithdraw.find((x) => x.Currency === formValues.values.transferCurrency).Realtime <= 0 ? AmountErrorMessage : ''}</span>
                      </div>
                    }
                    {!this.props.transferAccountBalance && <Field
                      component={RenderTextField}
                      name="transferAmount"
                      type="text"
                      pattern="^-?[\d,.]+$"
                      step="any"
                      id="transferAmount"
                      label="Amount"
                      normalize={this.formatAmount}
                      validationMessage={AmountErrorMessage}
                      onChange={this.handleChange}
                      required
                    />}
                    <Field
                      component={RenderDropdownField}
                      id="transferCurrency"
                      name="transferCurrency"
                      key={`${formValues.values.transferCurrency}_Code`}
                      label="Currency"
                      required
                      defaultValue="USD"
                      onChange={this.handleCurrencyChange}
                    >
                      <option />
                      {supportedCurrencies.map(code =>
                        <option
                          value={code}
                          key={code}
                        >
                          {code}
                        </option>
                      )}
                    </Field>
                  </div>
                  <div>
                    <span className="amount-validation">{this.state.error}</span>
                  </div>
                  {showWithdrawAll &&
                    <div>
                      <Field
                        component="input"
                        type="checkbox"
                        id="transferBalanceChosen"
                        name="transferBalanceChosen"
                      />
                      <label htmlFor="transferBalanceChosen">&nbsp;Withdraw All Available Cash</label>
                    </div>
                  }
                  <FrequencyFormField
                    id="frequency"
                    name="frequency"
                    label="Frequency"
                    className="field text is-dirty"
                    onChange={this.handleFrequencyChange}
                    frequencyOptions={frequencyOptions}
                    frequencyTooltipOptions={frequencyTooltipOptions}
                  />
                  {this.props.isOneTimeTransfer ? 
                    <Field
                    {...this.props}
                    component={RenderDatePickerField}
                    name="calendar"
                    type="text"
                    id="calendar"
                    label="Scheduled Date"
                    onChange={this.handleDateChange}
                    value={scheduledDate}
                    disableWeekends={true}
                    disablePastDays={true}
                    readOnly={true}
                    includeDates={bankingDays}
                    required
                  />
                  :   <div id="selectedFrequencyText">
                        {this.getSelectedFrequencyTooltipHtml()}
                      </div>             
                  }
                  <div className="actions">
                    <RippleButton disabled={this.state.error.length > 0} className="primary">{formValues.values.isEdit ? 'preview edit' : 'preview'}</RippleButton>
                  </div>
                </div>
              }
            </div>
          }          
        </section>
      </form>
    );
  }
}

SmartTransferForm.propTypes = {
  onInitializeForm: PropTypes.func.isRequired,
  originAccounts: PropTypes.arrayOf(PropTypes.shape()),
  destinationAccounts: PropTypes.arrayOf(PropTypes.shape()),
  onSelectOriginAccount: PropTypes.func.isRequired,
  onSelectDestinationAccount: PropTypes.func.isRequired,
  onAddBankAccount: PropTypes.func.isRequired,
  selectedOriginAccount: PropTypes.shape(),
  selectedDestinationAccount: PropTypes.shape(),
  supportedCurrencies: PropTypes.arrayOf(PropTypes.string),
  handleSubmit: PropTypes.func.isRequired,
  cloneRequest: PropTypes.shape(),
  selectPreviousRequest: PropTypes.func.isRequired,
  match: PropTypes.shape(),
  form: PropTypes.string,
  linkedAccount: PropTypes.shape(),
  setNewlyLinkedBank: PropTypes.func.isRequired,
  transferAccountBalance: PropTypes.bool,
  isJournal: PropTypes.bool,
  showWithdrawAll: PropTypes.bool,
  changeAmount: PropTypes.func.isRequired,
  changeScheduledDate: PropTypes.func.isRequired,
  changeCurrency: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    anyTouched: PropTypes.bool,
    values: PropTypes.shape({
      transferCurrency: PropTypes.string,
    }),
  }),
  amount: PropTypes.shape({
    current: PropTypes.number.isRequired,
  }).isRequired,
  allCashAmount: PropTypes.number.isRequired,
  isWireTransferEnabled: PropTypes.bool,
  isCheckTransferEnabled: PropTypes.bool,
  changeFrequency: PropTypes.func.isRequired,
  frequencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      description: PropTypes.string,
    }),
  ).isRequired,
  frequency: PropTypes.string,
  scheduledDate: PropTypes.shape({
    date: PropTypes.func,
    format: PropTypes.func,
  }).isRequired, 
  bankingDays: PropTypes.arrayOf(PropTypes.shape()),
  isOneTimeTransfer: PropTypes.bool,
};

export default reduxForm({
  form: 'cashACHForm',
  destroyOnUnmount: false,
})(SmartTransferForm);
